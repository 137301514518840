import { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, CardFooter, CardHeader, CardTitle } from "reactstrap";
import { setViewingUser } from 'store/functions/auth/auth';
import { CopyToClipboard } from "react-copy-to-clipboard";

import A from 'components/markup/links/A'

import api from 'api';

import { toast } from 'react-toastify';

import ObjectFinder from 'components/system/Objects/Finder';

import DayAndTimeRange from 'components/settings/DayAndTimeRange';
import TimeZone from 'components/settings/TimeZone';
import { toggleStandardLoader } from 'store/functions/system/system';
           
const Availability = ({ d, viewing_user, DOMAINS }) => {

    const link15 = d.booking_link_15_slug ? `${DOMAINS.BOOK}/${d.booking_link_15_slug}` : ''
    const link30 = d.booking_link_30_slug ? `${DOMAINS.BOOK}/${d.booking_link_30_slug}` : ''
    const link45 = d.booking_link_45_slug ? `${DOMAINS.BOOK}/${d.booking_link_45_slug}` : ''
    const link60 = d.booking_link_60_slug ? `${DOMAINS.BOOK}/${d.booking_link_60_slug}` : ''

    const onCreate = useCallback(async (type, isSync) => {

        toggleStandardLoader(true)
        const updated = await api.booking_links.createMine({
            type,
            division: d.division,
        })
        toggleStandardLoader(false)

        if(!updated.success) return toast.error(`Could not request a booking link at this time. Please contact support if the issue persists.`)

        if(isSync) {
            toast.success(`Availability Updated Successfully!`)
        } else {
            toast.success(`Booking link provisioned successfully!`)
        }

        setViewingUser()

    }, [d.division])

   
    return (
        <Card>
            <CardHeader>
                <CardTitle className='mb-0'><ObjectFinder collection="divisions" _id={d.division} /></CardTitle>
            </CardHeader>

            <div className='table-responsive'>
                <table className='table'>
                    <thead>
                        <tr>
                            <th>Booking Type</th>
                            <th>Booking Link</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>15 Minutes</td>
                            <td>{link15 ? <A href={link15}><i className="fas fa-copy text-success mr-2" /> {link15}</A> : <i className="fas fa-times text-danger" />}</td>
                            <td className='text-right'>
                                {link15 ? (
                                    <button style={{width: 130}} onClick={() => onCreate(15, true)} className='btn btn-sm btn-success'>Sync Availability</button>
                                ) : (
                                    <button style={{width: 130}} onClick={() => onCreate(15)} className='btn btn-sm btn-outline-info'>Request Link</button>
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td>30 Minutes</td>
                            <td>{link30 ? <A href={link30}><i className="fas fa-copy text-success mr-2" />{link30}</A> : <i className="fas fa-times text-danger" />}</td>
                            <td className='text-right'>
                                {link30 ? (
                                    <button style={{width: 130}} onClick={() => onCreate(30, true)} className='btn btn-sm btn-success'>Sync Availability</button>
                                ) : (
                                    <button style={{width: 130}} onClick={() => onCreate(30)} className='btn btn-sm btn-outline-info'>Request Link</button>
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td>45 Minutes</td>
                            <td>{link45 ? <A href={link45}><i className="fas fa-copy text-success mr-2" />{link45}</A> : <i className="fas fa-times text-danger" />}</td>
                            <td className='text-right'>
                                {link45 ? (
                                    <button style={{width: 130}} onClick={() => onCreate(45, true)} className='btn btn-sm btn-success'>Sync Availability</button>
                                ) : (
                                    <button style={{width: 130}} onClick={() => onCreate(45)} className='btn btn-sm btn-outline-info'>Request Link</button>
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td>60 Minutes</td>
                            <td>{link60 ? <A href={link60}><i className="fas fa-copy text-success mr-2" />{link60}</A> : <i className="fas fa-times text-danger" />}</td>
                            <td className='text-right'>
                                {link60 ? (
                                    <button style={{width: 130}} onClick={() => onCreate(60, true)} className='btn btn-sm btn-success'>Sync Availability</button>
                                ) : (
                                    <button style={{width: 130}} onClick={() => onCreate(60)} className='btn btn-sm btn-outline-info'>Request Link</button>
                                )}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </Card>
    )
}

const UserSettingsMatter = ({viewing_user, DOMAINS}) => {

    if(!viewing_user.division_overrides.length) return (
        <Card>
            <CardHeader>
                <CardTitle className="mb-0">Booking Links</CardTitle>
            </CardHeader>

            <CardBody>
                <p className='text-sm mb-0'>Your account is not setup to view calendar booking links. Contact your system admin.</p>
            </CardBody>
        </Card>
    )

    return viewing_user.division_overrides.map(d => 
        <Availability key={d._id} d={d} viewing_user={viewing_user} DOMAINS={DOMAINS} />
    )

}


const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user,
        DOMAINS: state.load.DOMAINS,
    };
};

export default connect(mapStateToProps, '')(UserSettingsMatter);