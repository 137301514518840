import { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Col, FormGroup, Input, Modal, Row } from "reactstrap";

import DatePicker from 'components/markup/inputs/Day';
import States from 'components/markup/inputs/States';
import Circle from 'components/markup/loading/Circle';

import api from 'api';
import moment from 'moment';

import { toast } from 'react-toastify';
import ConfirmationModal from 'components/functional/modals/Confirmation';

import ReactSelect from 'components/functional/inputs/ReactSelect';
import { formatCurrency } from 'utils/currency';


const ModalCreditorsEdit = ({showModal, toggleModal, onSuccess, defaultSchedule, creditor_id, matter}) => {

    const [err, setErr] = useState(false)
    const [creditor, setCreditor] = useState(null)
    const [showDelete, setShowDelete] = useState(false)

    const onInputChange = useCallback((field, val) => {
        const _creditor = JSON.parse(JSON.stringify(creditor));
        _creditor[field] = val;
        setCreditor(_creditor)
    }, [creditor])

    const onSave = useCallback(async () => {
        if(!creditor.name) return toast.info(`A name must be set before updating a creditor`);
        if(!creditor.account_identifier) return toast.info(`An account identifier must be set before updating a creditor. Enter a dash if you do not have one.`);

        const params = {
            ...creditor,
            account_identifier: creditor.account_identifier,
            matter: matter._id,
            balance: creditor.balance ? parseFloat(creditor.balance) : 0,
            monthly_payment: creditor.monthly_payment ? parseFloat(creditor.monthly_payment) : 0,
            claim_amount: creditor.claim_amount ? parseFloat(creditor.claim_amount) : 0,
            unsecured_amount: creditor.unsecured_amount ? parseFloat(creditor.unsecured_amount) : 0,
        }

        let result;

        if(creditor_id) {
            result = await api.modules.bk.creditors.update(creditor._id, params)
        } else {
            result = await api.modules.bk.creditors.create(params)
        }

        

        if(!result.success) return toast.error(`Something went wrong updating this creditor, please refresh your page`)
        
        toast.success(`Creditor updated successfully`);
        toggleModal()
        if(onSuccess) onSuccess(result.data)

    }, [creditor, creditor_id, matter._id, onSuccess, toggleModal])
  
    const onDelete = useCallback(async () => {
        if(!creditor.name) return toast.info(`A name must be set before updating a creditor`);

        const result = await api.modules.bk.creditors.delete(creditor._id)

        if(!result.success) return toast.error(`Something went wrong deleting this creditor, please refresh your page`)
        
        toast.success(`Creditor deleted successfully`);
        toggleModal()
        if(onSuccess) onSuccess(result.data)

    }, [creditor, onSuccess, toggleModal])

    const fetchData = useCallback(async () => {
        if(showModal) {
            if(creditor_id) {
                const result = await api.modules.bk.creditors.findById(creditor_id);
                if(!result.success) return setErr(true);
                setCreditor(result.data)
            } else {
                setCreditor({
                    schedule: defaultSchedule ? defaultSchedule : 'F'
                })
            }
           
        } else {
            setErr(false)
        }
    }, [creditor_id, defaultSchedule, showModal])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    return (

        <>

        <Modal
            className="modal-dialog-centered"
            isOpen={showModal}
            toggle={toggleModal}
            size="lg"
            zIndex={2000}
        >

            <div className="modal-header">
                <h5 className="modal-title">Edit A Creditor</h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggleModal}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>

            {err ? (
                <div className="modal-body">
                    <div className='alert alert-danger mb-0'>Something went wrong loading this creditor, please refresh your page.</div>
                </div>
            ) : !creditor ? (
                <div className="modal-body">
                    <Circle />
                </div>
            )  : (
                <div>

                    <div className="modal-body border-bottom">

                        <h2 className='text-uppercase text-cente mb-0 pb-3 pt-3'>Creditor Info</h2>

                        <FormGroup>
                            <label className='form-control-label'>Creditor Name</label>
                            <Input 
                                type="text"
                                value={creditor.name}
                                onChange={(e) => onInputChange('name', e.target.value)}
                            />
                        </FormGroup>

                        <ReactSelect
                            title="Type Of Debt"
                            formGroup={true}
                            onChange={(obj) => onInputChange('schedule', obj.value)}
                            options={[
                                { value: 'D', label: 'Secured (Schedule D)' },
                                { value: 'E', label: 'Priority Unsecured (Schedule E)' },
                                { value: 'F', label: 'Unsecured (Schedule F)' },
                            ]}
                            value={creditor.schedule}
                        />    

                        <Row>
                            <Col xs={3}>
                                <DatePicker 
                                    title={`Date Incurred`}
                                    value={creditor.date_incurred ? moment(creditor.date_incurred).toDate() : ''} 
                                    onChange={day => onInputChange('date_incurred', moment(day).format('MM/DD/YYYY'))} 
                                />
                            </Col>
                            <Col xs={3}>
                                <DatePicker 
                                    title={`Last Payment Date`}
                                    value={creditor.last_payment_date ? moment(creditor.last_payment_date).toDate() : ''} 
                                    onChange={day => onInputChange('last_payment_date', moment(day).format('MM/DD/YYYY'))} 
                                />
                            </Col>

                            <Col xs={3}>
                                <FormGroup>
                                    <label className='form-control-label'>Balance: {creditor.balance ? formatCurrency(creditor.balance) : '$0.00'}</label>
                                    <Input 
                                        type="number"
                                        value={creditor.balance}
                                        onChange={(e) => onInputChange('balance', e.target.value)}
                                    />
                                </FormGroup>
                            </Col>

                            <Col xs={3}>
                                <FormGroup>
                                    <label className='form-control-label'>Monthly Payment: {creditor.monthly_payment ? formatCurrency(creditor.monthly_payment) : '$0.00'}</label>
                                    <Input 
                                        type="number"
                                        value={creditor.monthly_payment}
                                        onChange={(e) => onInputChange('monthly_payment', e.target.value)}
                                    />
                                </FormGroup>
                             
                            </Col>
                        </Row>

                        <FormGroup>
                            <label className='form-control-label'>Account Number (Only last 4 digits will be shown)</label>
                            <Input 
                                type="number"
                                value={creditor.account_identifier}
                                onChange={(e) => onInputChange('account_identifier', e.target.value)}
                            />
                        </FormGroup>

                    </div>


                    <div className="modal-body border-bottom bg-secondary">
                        <Row>
                            <Col xs={6}>
                                <h2 className='text-uppercase text-cente mb-0 pb-3 pt-3'>Creditor Contact Info</h2>

                                <FormGroup>
                                    <label className='form-control-label'>Phone Number</label>
                                    <Input 
                                        type="text"
                                        value={creditor.phone}
                                        onChange={(e) => onInputChange('phone', e.target.value)}
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <label className='form-control-label'>Email</label>
                                    <Input 
                                        type="text"
                                        value={creditor.email}
                                        onChange={(e) => onInputChange('email', e.target.value)}
                                    />
                                </FormGroup>

                            </Col>

                            <Col xs={6}>
                                <h2 className='text-uppercase text-cente mb-0 pb-3 pt-3'>Creditor Address</h2>

                                <div>
                                    
                                    <FormGroup>
                                        <label className="form-control-label">Street Address</label>
                                        <Input 
                                            type="text"
                                            value={creditor.street}
                                            onChange={e => onInputChange('street', e.target.value)}
                                        />
                                    </FormGroup>    
                                    
                                    <Row>
                                        <Col lg={4}>
                                            <FormGroup>
                                                <label className="form-control-label">City</label>
                                                <Input 
                                                    type="text"
                                                    value={creditor.city}
                                                    onChange={e => onInputChange('city', e.target.value)}
                                                />
                                            </FormGroup>    
                                        </Col>
                                        <Col lg={4}>
                                            <States 
                                                title="State"
                                                value={creditor.state}
                                                onChange={(val) => onInputChange('state', val)}
                                            /> 
                                        </Col>
                                        <Col lg={4}>
                                            <FormGroup>
                                                <label className="form-control-label">Zip</label>
                                                <Input 
                                                    type="text"
                                                    value={creditor.postal_code}
                                                    onChange={e => onInputChange('postal_code', e.target.value)}
                                                />
                                            </FormGroup>    
                                        </Col>
                                    </Row>
                                </div>

                            </Col>
                        </Row>
                    </div>
                  
                    <div className="modal-body border-bottom">
                        <h2 className='text-uppercase text-cente mb-0 pb-3 pt-3'>Claim Amounts</h2>
                        <Row>
                            <Col xs={6}>
                                <FormGroup>
                                    <label className='form-control-label'>Claim Amount: {creditor.claim_amount ? formatCurrency(creditor.claim_amount) : '$0.00'}</label>
                                    <Input 
                                        type="number"
                                        value={creditor.claim_amount}
                                        onChange={(e) => onInputChange('claim_amount', e.target.value)}
                                    />
                                </FormGroup>
                            </Col>

                            <Col xs={6}>
                                <FormGroup>
                                    <label className='form-control-label'>Unsecured Amount: {creditor.unsecured_amount ? formatCurrency(creditor.unsecured_amount) : '$0.00'}</label>
                                    <Input 
                                        type="number"
                                        value={creditor.unsecured_amount}
                                        onChange={(e) => onInputChange('unsecured_amount', e.target.value)}
                                    />
                                </FormGroup>
                             
                            </Col>
                        </Row>

                        <ReactSelect
                            title={"Who Owes The Debt"}
                            formGroup={true}
                            onChange={(obj) => onInputChange('ownership', obj.value)}
                            options={[
                                { value: 'd1', label: 'Debtor 1' },
                                { value: 'd2', label: 'Debtor 2' },
                                { value: 'd1_and_d2', label: 'Debtor 1 & 2' },
                                { value: 'd_and_other', label: 'At least one debtor and another' },
                            ]}
                            value={creditor.ownership}
                        />    
                    </div>

                    <div className="modal-body border-bottom  bg-secondary">
                        <h2 className='text-uppercase text-cente mb-0 pb-3 pt-3'>Claim Info</h2>
                        <Row>
                            <Col xs={6}>
                                <p className='text-sm mb-0 font-weight-bold text-dark'>Nature Of Lien (check all that apply)</p>
                                <FormGroup>
                                    <div className="custom-control custom-checkbox">
                                        <input
                                            className="custom-control-input"
                                            id="archk-lien-agreement"
                                            type="checkbox"
                                            checked={creditor.lien_agreement}
                                            onChange={() => onInputChange('lien_agreement', !creditor.lien_agreement)}
                                            />
                                        <label className="custom-control-label" htmlFor="archk-lien-agreement">
                                            An Agreement You Made
                                        </label>
                                    </div>
                                </FormGroup>
                                <FormGroup>
                                    <div className="custom-control custom-checkbox">
                                        <input
                                            className="custom-control-input"
                                            id="archk-lien-financial"
                                            type="checkbox"
                                            checked={creditor.lien_statutory}
                                            onChange={() => onInputChange('lien_statutory', !creditor.lien_statutory)}
                                            />
                                        <label className="custom-control-label" htmlFor="archk-lien-financial">
                                            Statutory Lien
                                        </label>
                                    </div>
                                </FormGroup>
                                <FormGroup>
                                    <div className="custom-control custom-checkbox">
                                        <input
                                            className="custom-control-input"
                                            id="archk-lien-judgement"
                                            type="checkbox"
                                            checked={creditor.lien_judgement}
                                            onChange={() => onInputChange('lien_judgement', !creditor.lien_judgement)}
                                            />
                                        <label className="custom-control-label" htmlFor="archk-lien-judgement">
                                            Judgement Lien From Lawsuit
                                        </label>
                                    </div>
                                </FormGroup>
                                <ReactSelect
                                    title={"Other"}
                                    formGroup={true}
                                    menuPlacement="top"
                                    onChange={(obj) => onInputChange('lien_other', obj.value)}
                                    options={[
                                        { value: 'Common Law Lien', label: 'Common Law Lien' },
                                        { value: 'Deed Of Trust', label: 'Deed Of Trust' },
                                        { value: 'First Mortgage', label: 'First Mortgage' },
                                        { value: 'Garnishment', label: 'Garnishment' },
                                        { value: 'Judgement Lien', label: 'Judgement Lien' },
                                        { value: 'Mechanics Lien', label: 'Mechanics Lien' },
                                        { value: 'Mortgage', label: 'Mortgage' },
                                        { value: 'Second Mortgage', label: 'Second Mortgage' },
                                        { value: 'Third Mortgage', label: 'Third Mortgage' },
                                        { value: 'Non Purchase Money Security', label: 'Non Purchase Money Security' },
                                        { value: 'Statutory Lien', label: 'Statutory Lien' },
                                    ]}
                                    value={creditor.lien_other}
                                />    
                            </Col>

                            <Col xs={6}>
                                <p className='text-sm mb-0 font-weight-bold text-dark'>As of the File date the claim is: (check all that apply)</p>
                                <FormGroup>
                                    <div className="custom-control custom-checkbox">
                                        <input
                                            className="custom-control-input"
                                            id="archk-contingent"
                                            type="checkbox"
                                            checked={creditor.contingent}
                                            onChange={() => onInputChange('contingent', !creditor.contingent)}
                                            />
                                        <label className="custom-control-label" htmlFor="archk-contingent">
                                            Contingent
                                        </label>
                                    </div>
                                </FormGroup>
                                <FormGroup>
                                    <div className="custom-control custom-checkbox">
                                        <input
                                            className="custom-control-input"
                                            id="archk-unliquidated"
                                            type="checkbox"
                                            checked={creditor.unliquidated}
                                            onChange={() => onInputChange('unliquidated', !creditor.unliquidated)}
                                            />
                                        <label className="custom-control-label" htmlFor="archk-unliquidated">
                                            Unliquidated
                                        </label>
                                    </div>
                                </FormGroup>
                                <FormGroup>
                                    <div className="custom-control custom-checkbox">
                                        <input
                                            className="custom-control-input"
                                            id="archk-disputed"
                                            type="checkbox"
                                            checked={creditor.disputed}
                                            onChange={() => onInputChange('disputed', !creditor.disputed)}
                                            />
                                        <label className="custom-control-label" htmlFor="archk-disputed">
                                            Disputed
                                        </label>
                                    </div>
                                </FormGroup>

                                <hr className='my-2' />

                                <FormGroup>
                                    <div className="custom-control custom-checkbox">
                                        <input
                                            className="custom-control-input"
                                            id="archk-hide_schedule_d"
                                            type="checkbox"
                                            checked={creditor.hide_schedule_d}
                                            onChange={() => onInputChange('hide_schedule_d', !creditor.hide_schedule_d)}
                                            />
                                        <label className="custom-control-label" htmlFor="archk-hide_schedule_d">
                                            Do not print or total on Schedule D
                                        </label>
                                    </div>
                                </FormGroup>
                               
                                <FormGroup>
                                    <div className="custom-control custom-checkbox">
                                        <input
                                            className="custom-control-input"
                                            id="archk-exclude_from_mailing_matrix"
                                            type="checkbox"
                                            checked={creditor.exclude_from_mailing_matrix}
                                            onChange={() => onInputChange('exclude_from_mailing_matrix', !creditor.exclude_from_mailing_matrix)}
                                            />
                                        <label className="custom-control-label" htmlFor="archk-exclude_from_mailing_matrix">
                                            Exclude from mailing matrix
                                        </label>
                                    </div>
                                </FormGroup>
                             
                            </Col>
                        </Row>
                    </div>


                </div>
            )}

            <div className="modal-footer">
                <button className="btn btn-outline-warning" onClick={toggleModal}>
                    Close
                </button>
                {!err && creditor ? (
                    <div>
                        {creditor._id ? (
                            <button className="btn btn-outline-danger" onClick={() => setShowDelete(true)}>
                                <i className="fas fa-trash mr-2" /> Delete
                            </button>
                        ) : ''}
                        <button className="btn btn-success" onClick={onSave}>
                            <i className="fas fa-save mr-2" /> Save
                        </button>
                    </div>
                ) : ''}
            </div>

        </Modal>

        <ConfirmationModal 
            showModal={showDelete}
            toggleModal={() => setShowDelete(false)}
            title="Delete Creditor"
            body={<span>Are you sure you sure to delete this creditor.</span>}
            onConfirmation={onDelete}
            zIndex={2000}
        />

        </>

    )
}

const mapStateToProps = state => {
    return {
        matter: state.matters.selected_matter,
    };
};

export default connect(mapStateToProps, '')(ModalCreditorsEdit);