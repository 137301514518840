import { FormGroup, Input, Row, Col } from "reactstrap";

const ContactsUpdate = ({onInputChange, contact}) => {
    return (
        <div>
            
        <Row>
            <Col lg={6}>
                <FormGroup>
                    <label className="form-control-label">Email</label>
                    <Input 
                        type="text"
                        value={contact.email}
                        onChange={e => onInputChange('email', e.target.value)}
                    />
                </FormGroup>    
                <FormGroup>
                    <label className="form-control-label">Email 2</label>
                    <Input 
                        type="text"
                        value={contact.email_2}
                        onChange={e => onInputChange('email_2', e.target.value)}
                    />
                </FormGroup>    
                <FormGroup>
                    <label className="form-control-label">Email 3</label>
                    <Input 
                        type="text"
                        value={contact.email_3}
                        onChange={e => onInputChange('email_3', e.target.value)}
                    />
                </FormGroup>    
            </Col>
            <Col lg={6}>
                <FormGroup>
                    <label className="form-control-label">Phone</label>
                    <Input 
                        type="text"
                        value={contact.phone}
                        onChange={e => onInputChange('phone', e.target.value)}
                    />
                </FormGroup>    
                <FormGroup>
                    <label className="form-control-label">Phone 2</label>
                    <Input 
                        type="text"
                        value={contact.phone_2}
                        onChange={e => onInputChange('phone_2', e.target.value)}
                    />
                </FormGroup>    
                <FormGroup>
                    <label className="form-control-label">Phone 3</label>
                    <Input 
                        type="text"
                        value={contact.phone_3}
                        onChange={e => onInputChange('phone_3', e.target.value)}
                    />
                </FormGroup>    
            </Col>
        </Row>

        <hr />

        <FormGroup>
            <label className="form-control-label">Fax Number</label>
            <Input 
                type="text"
                value={contact.fax_number}
                onChange={e => onInputChange('fax_number', e.target.value)}
            />
        </FormGroup>    

    </div>
    )

}

export default ContactsUpdate;