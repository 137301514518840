import { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { Modal, FormGroup, Input, Badge, Row, Col } from "reactstrap";
import { useCallback } from 'react';

import api from 'api';

import { toast } from 'react-toastify'

import moment from 'moment';

import ReactSelect from 'components/functional/inputs/ReactSelect';
import ObjectFinder from 'components/system/Objects/Finder';
import Circle from 'components/markup/loading/Circle';
import ConfirmationModal from 'components/functional/modals/Confirmation';

import DatePicker from 'components/markup/inputs/Day';

import SearchCollections from "components/system/Search/Collections";

import Checklist from './Checklist';
import Documents from './Documents';
import UserComments from 'components/system/UserComments';

import { v4 as uuidv4 } from 'uuid';

const OutstandingItemsEdit = ({
    showModal, 
    toggleModal, 
    title,
    selected_matter, 
    findTasks, 
    item, 
    defaultName, 
    defaultDescription, 
    size, 
    viewing_user, 
    task, 
    onItemChange, 

    mod, 
    metadata, 
    categories, 
    itemTypes,

    defaultIsDeadline,
    defaultCategory,
    defaultChecklist,

    modalIndex, 
    defaultLockTitles,

}) => {

    const parties = selected_matter.parties.filter(p => p.contact);

    const [hasLeftComment, setHasLeftComment] = useState(false);
    const [loading, setLoading] = useState(false);
    const [err, setErr] = useState(false);

    const [showCloseMessage, setShowCloseMessage] = useState(false);
    

    const [itemType, setItemType] = useState(false);
    const [saving, setSaving] = useState(false);
    const [lockTitles, setLockTitles] = useState();

    const [id, setId] = useState('');
    const [priority, setPriority] = useState(3);
    const [clientActionNeeded, setClientActionNeeded] = useState(false);
    const [contact, setContact] = useState('');
    const [name, setName] = useState(defaultName || '');
    const [category, setCategory] = useState(defaultCategory ? defaultCategory : '');
    const [description, setDescription] = useState(defaultDescription || '');
    
    const [followUp, setFollowUp] = useState('');
    const [followUpInterval, setFollowUpInterval] = useState('');
    const [originalDueDate, setOriginalDueDate] = useState('');
    const [dueDate, setDueDate] = useState('');
    const [assignedTo, setAssignedTo] = useState([]);
    
    const [checklist, setChecklist] = useState(defaultChecklist ? defaultChecklist.map(c => { return { name: c, id: uuidv4() } }) : []);
    const [documents, setDocuments] = useState([]);

    const onClose = useCallback(() => {

    }, [])

    const onCommentLeft = useCallback(() => {
        setHasLeftComment(true)
    }, [])

    const onSetItemType = useCallback((obj) => {
        setItemType(obj)
        if(obj.checklist) {
            setChecklist(obj.checklist.map(c => { return { name: c, id: uuidv4() } }))
        }
    }, [])

    const onAddAssignedTo = useCallback((_ids) => {
        let to = JSON.parse(JSON.stringify(assignedTo));

        _ids.forEach(_id => {
            if(!to.includes(_id)) to.push(_id)
        }) 
       
        setAssignedTo(to)
    }, [assignedTo])
 
    const onRemoveAssignedTo = useCallback((id) => {
        let to = JSON.parse(JSON.stringify(assignedTo));

        to = to.filter(t => t !== id)
       
        setAssignedTo(to)
    }, [assignedTo])
    
    const onAddDepartment = useCallback(async (department) => {
        const users = await api.division_departments.findUsers(department);
        if(users.data) onAddAssignedTo(users.data)
    }, [onAddAssignedTo])

    const onSave = useCallback(async () => {
        // if(!contact) return toast.info(`An outstanding item must have an assigned contact.`)
        if(!name && !itemType) return toast.info(`An outstanding item must have an valid name or type entered.`)

        // const thisMorning = moment().startOf('day').format('YYYY-MM-DD')

        if(task && !dueDate) return toast.info(`This item must have a due date.`)
        if(!assignedTo.length) return toast.info(`This item must be assigned to at least 1 person.`)

        // if(followUp && moment(followUp).isBefore(thisMorning)) return toast.info(`Follow Up Date Must Be In The Future`)
        // if(dueDate && moment(dueDate).isBefore(thisMorning)) return toast.info(`Due Date Must Be In The Future`)

        if(originalDueDate) {
            if(parseInt(moment(originalDueDate).format('X')) !== parseInt(moment(dueDate).format('X')) && !hasLeftComment) {
                return toast.info(`If you are changing the due date of an item you must leave a comment first.`)
            }
        }

        const params = {
            contact: contact ? contact : undefined,
            description,
            priority,
            checklist,
            client_action_needed  : clientActionNeeded,
            name                  : itemType ? itemType.label : name,
            assigned_to           : assignedTo,
            matter                : selected_matter._id,
            follow_up_interval    : followUpInterval,
            next_follow_up_at     : followUp ? parseInt(moment(followUp).format('X')) : 0,
            due_date              : dueDate ? parseInt(moment(dueDate).format('X')) : 0,
        }

        if(!id) {
            params.is_deadline = itemType && itemType.is_deadline ? true : defaultIsDeadline ? true : false
            params.category = itemType && itemType.category ? itemType.category : category ? category : undefined
            params.metadata = metadata ? metadata : undefined
            params.module = mod ? mod : undefined
        }

        if(task) params.task = task;

        let saved;
        setSaving(true)
        if(id) {
            saved = await api.outstanding_items.update(id, params)
        } else {
            saved = await api.outstanding_items.create({ ...params, lock_titles: defaultLockTitles ? true : false})
        }   
        setSaving(false);
       
        if(!saved.success) return toast.error(`Failed to save this outstanding item at this time, please try again.`)

        setContact('')
        setName('')
        setDescription('')
        setFollowUp('')
        setFollowUpInterval('')
        setDueDate('')
        setAssignedTo([])
        toggleModal()
        setOriginalDueDate()
        setLockTitles(false)
        setClientActionNeeded(false)
        setCategory(defaultCategory ? defaultCategory : '')
        setChecklist([])
        toast.success(`Outstanding Item ${id ? 'Updated' : 'Created'} Successfully`)
        if(findTasks) findTasks()
        if(onItemChange) onItemChange('updated', saved)
    }, [name, itemType, task, dueDate, assignedTo, originalDueDate, contact, description, priority, checklist, clientActionNeeded, selected_matter._id, followUpInterval, followUp, id, toggleModal, defaultCategory, findTasks, onItemChange, hasLeftComment, defaultIsDeadline, category, metadata, mod, defaultLockTitles])

    const fetchData = useCallback(async () => {
        setLoading(true)
        const result = await api.outstanding_items.findById(item._id)
        setLoading(false)
        const _item = result.data;
        if(!_item) return setErr(true)

        setContact(_item.contact)
        setName(_item.name)
        setDescription(_item.description)
        setFollowUpInterval(_item.follow_up_interval)
        setFollowUp(_item.next_follow_up_at ? moment.unix(_item.next_follow_up_at).toDate() : '')
        setDueDate(_item.due_date ? moment.unix(_item.due_date).toDate() : '')
        setOriginalDueDate(_item.due_date ? moment.unix(_item.due_date).toDate() : '')
        setId(_item._id)
        setClientActionNeeded(_item.client_action_needed)
        setAssignedTo(_item.assigned_to)
        setPriority(_item.priority)
        setLockTitles(_item.lock_titles)
        setCategory(_item.category ? item.category : '')
        setChecklist(_item.checklist ? _item.checklist : [])
        setDocuments(_item.documents ? _item.documents : [])

    }, [item])

    useEffect(() => {
        if(showModal) {
            setChecklist([])
            if(item && item._id) {
                fetchData()
            } else {
                if(parties && parties[0] && parties[0].contact) setContact(parties[0].contact._id)
                setAssignedTo([viewing_user._id])
            }
        }
    //eslint-disable-next-line
    }, [showModal])

    return (

        <Modal
            className="modal-dialog-centered"
            isOpen={showModal}
            toggle={toggleModal}
            size={size ? size : 'lg'}
            zIndex={modalIndex}
        >

            <div className="modal-header">
                <h5 className="modal-title">
                    {title ? title : (
                        <span>{id ? "Edit" : 'Add'} An Outstanding Item</span>
                    )}
                </h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggleModal}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>

            {err ? (
                <div className='modal-body'>
                    <div className='alert alert-danger mb-0'>Could not load item at this time, please try again or refresh your page.</div>
                </div>
            ) : loading ? (
                <div className='modal-body'>
                    <Circle />
                </div>
            ) : (
                <div className="modal-body">

                    <Row>
                        <Col md={6}>

                            {itemTypes ? (
                                <ReactSelect
                                    title={"Select Item"}
                                    formGroup={true}
                                    placeholder=""
                                    onChange={(obj) => onSetItemType(obj)}
                                    options={itemTypes}
                                    value={itemType}
                                /> 
                            ) : (
                                    <FormGroup>
                                    <label className='form-control-label'>Item Name</label>
                                    <Input 
                                        type="text"
                                        disabled={lockTitles}
                                        value={name}
                                        onChange={e => {
                                            const val = e.target.value;
                                            if(val && val.length > 50) return;
                                            setName(val)
                                        }}
                                    />
                                    <p className='text-right text-sm mb--4'>{name ? name.length : 0}/50 Characters</p>
                                </FormGroup>
                            )}
                           

                            <FormGroup>
                                <label className='form-control-label'>Description</label>
                                <Input 
                                    disabled={lockTitles}
                                    type="textarea"
                                    value={description}
                                    onChange={e => setDescription(e.target.value)}
                                />
                            </FormGroup>

                            <Checklist 
                                checklist={checklist} 
                                setChecklist={setChecklist}
                            />


                            {id ? (
                                <div>
                                   
                                    <hr className='mt-0' />

                                    <UserComments 
                                        collection_name="outstanding_items" 
                                        collection_id={id}
                                        parent_name={name}
                                        onCommentLeft={onCommentLeft}
                                    />
                                </div>

                            ) : ''}
                         
                        </Col>

                        <Col md={6}>

                            <DatePicker 
                                value={dueDate} 
                                onChange={day => setDueDate(day)} 
                                title={`Item Due On`}
                                showTimeSelect={true}
                                dateFormat="Pp"
                            />

                            <ReactSelect
                                title={"Associated Contact"}
                                formGroup={true}
                                placeholder=""
                                onChange={(obj) => setContact(obj.value)}
                                options={
                                    parties.map(p => {
                                        const identifier = p.contact.display_name ? p.contact.display_name : p.contact.phone ? p.contact.phone : p.contact.email;
                                        return { 
                                            label: p.workflow_contact ? identifier + ' - ' + p.workflow_contact.name : 'Not Found',
                                            value: p.contact._id
                                        }
                                    })
                                }
                                value={contact}
                            />    

                            <Row>
                                <Col md={6}>
                                    <SearchCollections
                                        collection={'users'}
                                        title="Add Specific User"
                                        placeholder="Search Users..."
                                        hideSelected={true}
                                        onChange={(obj) => onAddAssignedTo([obj.value])}
                                        filter={{
                                            divisions: { $in: [selected_matter.division] }
                                        }}
                                    /> 
                                </Col>
                                <Col md={6}>
                                    <SearchCollections
                                        collection={'division_departments'}
                                        title="Assign A Department"
                                        placeholder="Search Departments..."
                                        hideSelected={true}
                                        onChange={(obj) => onAddDepartment([obj.value])}
                                        filter={{
                                            division: selected_matter.division
                                        }}
                                    /> 
                                </Col>
                            </Row>

                            {assignedTo.length ? assignedTo.map((i) => (
                                <Badge key={i} color="success" className="ml-0 mb-2 text-left mr-3">
                                    <ObjectFinder collection="users" _id={i} />
                                    <span className="pr-2 pl-3 cursor-pointer float-right" onClick={() => onRemoveAssignedTo(i)}>
                                        <i className="fas fa-times " />
                                    </span>
                                </Badge>
                            )) : null}

                            <hr />

                            

                            <ReactSelect
                                title={"Priority"}
                                formGroup={true}
                                placeholder=""
                                onChange={(obj) => setPriority(obj.value)}
                                options={[
                                    { label: 'Priority 1 (Highest)', value: 1 },
                                    { label: 'Priority 2 (High)', value: 2 },
                                    { label: 'Priority 3 (Default)', value: 3 },
                                    { label: 'Priority 4 (Low)', value: 4 },
                                    { label: 'Priority 5 (Lowest)', value: 5 },
                                ]}
                                value={priority}
                            />    
                            {categories && categories.length ? (
                                <ReactSelect
                                    title={"Category"}
                                    formGroup={true}
                                    placeholder=""
                                    onChange={(obj) => setCategory(obj.value)}
                                    options={categories.map(c => {
                                        return { label: c, value: c }
                                    })}
                                    value={category}
                                />    
                            ) : ''}
                           
                           
                            {/* <ReactSelect
                                title={"Category"}
                                formGroup={true}
                                placeholder=""
                                onChange={(obj) => setPriority(obj.value)}
                                options={[
                                    { label: 'Follow Up', value: 1 },
                                    { label: 'Personal', value: 2 },
                                    { label: 'Priority 3 (Default)', value: 3 },
                                    { label: 'Priority 4 (Low)', value: 4 },
                                    { label: 'Priority 5 (Lowest)', value: 5 },
                                ]}
                                value={priority}
                            />     */}

                            <DatePicker 
                                value={followUp} 
                                onChange={day => setFollowUp(day)} 
                                title={`Next Follow Up Date`}
                                showTimeSelect={true}
                                dateFormat="Pp"
                            />

                            <ReactSelect
                                title={"Follow Up Interval"}
                                formGroup={true}
                                placeholder=""
                                onChange={(obj) => setFollowUpInterval(obj.value)}
                                options={[
                                    { label: 'Only Follow Up On Above Date', value: '0' },
                                    { label: '1st Of Month', value: '1st_of_the_month' },
                                    { label: 'Every Day', value: '1' },
                                    { label: 'Every 2 Days', value: '2' },
                                    { label: 'Every 3 Days', value: '3' },
                                    { label: 'Every 4 Days', value: '4' },
                                    { label: 'Every 5 Days', value: '5' },
                                    { label: 'Every 6 Days', value: '6' },
                                    { label: 'Every Week', value: '7' },
                                    { label: 'Every 2 Weeks', value: '14' },
                                    { label: 'Every 3 Weeks', value: '21' },
                                    { label: 'Every 4 Weeks', value: '28' },
                                ]}
                                value={followUpInterval}
                            />    

                            <hr className='mb-2 mt-0' />

                            <FormGroup>
                                <div className="custom-control custom-checkbox">
                                    <input
                                        className="custom-control-input"
                                        id="archk-client-action-needed"
                                        type="checkbox"
                                        checked={clientActionNeeded}
                                        onChange={(e) => setClientActionNeeded(!clientActionNeeded)}
                                    />
                                    <label className="custom-control-label" htmlFor="archk-client-action-needed">
                                        Client Action Required
                                    </label>
                                </div>
                            </FormGroup>
                            
                            {id ? (
                                <div>
                                    <hr className='mb-2 mt-0' />

                                    <Documents 
                                        id={id}
                                        documents={documents}
                                    />

                                </div>
                            ) : ''}

                            
                        </Col>

                    
                    </Row>

                

                </div>
            )}

            <div className="modal-footer">
                <button className="btn btn-outline-warning" onClick={toggleModal}>
                    Close
                </button>
                
                {!err && !loading ? (
                    <button className="btn btn-success" onClick={onSave} disabled={saving}>
                        <i className="fas fa-save mr-2" /> Save
                    </button>
                ) : ''}
            </div>

            <ConfirmationModal 
                showModal={showCloseMessage}
                toggleModal={() => setShowCloseMessage(false)}
                title="Close Editor"
                body={<span>Are you sure you wish to close this editor? All unsaved changes will be lost.</span>}
                onConfirmation={toggleModal}
                zIndex={2000}
            />

        </Modal>

    )
}

const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user,
        selected_matter: state.matters.selected_matter,
    };
};

export default connect(mapStateToProps, '')(OutstandingItemsEdit);