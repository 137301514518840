import { memo, useCallback, useEffect, useState } from "react";
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Card, CardBody, CardFooter, CardHeader, CardTitle, Container } from "reactstrap";


import HeaderNavigation from 'components/markup/layout/HeaderNavigation';
import A from 'components/markup/links/A';
import Circle from 'components/markup/loading/Circle';

import api from 'api';
import ApiError from 'components/markup/layout/ApiError';

import ObjectFinder from 'components/system/Objects/Finder';

import SearchCollections from "components/system/Search/Collections";

const MattersViewMainLogs = ({ selected_division, DOMAINS }) => {

    const [err, setErr] = useState(false)
    const [data, setData] = useState(null)
    const [user, setUser] = useState(null)

    const fetchData = useCallback(async () => {
        const result = await api.booking_links.search({ division: selected_division._id, limit: 100 })
        if(!result.success) setErr(result.message)
        setData(result.data.documents)
    }, [selected_division._id])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    if(err) return <ApiError err={err} className="py-4 mx-4" /> 
    if(!data) return <Circle />

    return (
        <>

        <Helmet>
            <title>Directory</title>
            <meta name="description" content="Directory" />
        </Helmet>

        <HeaderNavigation 
            title="Directory"
            description="This page allows you to view company and individual user's booking links.."
        />

        <Container fluid>
        
            <Card id="archk-timeline-logs" className=''>

                <CardHeader>
                    <CardTitle className='mb-0'>Company Booking Links</CardTitle>
                </CardHeader>

                <div className='table-responsive'>
                    <table className='table'>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Link</th>
                                <th></th>
                            </tr>
                        </thead>

                        <tbody>
                            {data.length ? data.map(d => {

                                const link = `${DOMAINS.BOOK}/${d.slug}`

                                return (
                                    <tr key={d._id}>
                                        <td>{d.name}</td>
                                        <td><div style={{whiteSpace: 'pre'}}><A href={link}>{link}</A></div></td>
                                        <td className='text-right'><A className="btn btn-success btn-sm" href={link}><i className="fas fa-link mr-2" /> Open</A></td>
                                    </tr>
                                )
                            }) : (
                                <tr>
                                    <td>No Links Found</td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>

            </Card>

            <Card>
                <CardHeader>
                    <CardTitle className='mb-0'>Search Team Members</CardTitle>
                </CardHeader>

                <CardBody>
                    <SearchCollections
                        collection={'users'}
                        title={"Search"}
                        hideTitle={true}
                        includeFields={[]}
                        value={user ? user._id : null}
                        onChange={(obj) => setUser(obj.obj)}
                        filter={{
                            division:selected_division._id
                        }}
                    /> 
                </CardBody>

                
                    {user ? (
                        <div>
                            {user.division_overrides.length ? user.division_overrides.map(d => (
                                <div>
                                    <CardHeader>
                                        <CardTitle className='mb-0'><ObjectFinder collection="divisions" _id={d.division}></ObjectFinder></CardTitle>
                                    </CardHeader>
                                    <table className='table'>
                                        <tbody>
                                            <tr>
                                                <td>15 Minute Booking</td>
                                                <td>
                                                    {d.booking_link_15_slug ? <A href={`${DOMAINS.BOOK}/${d.booking_link_15_slug}`}>{DOMAINS.BOOK}/{d.booking_link_15_slug}</A> : '-'}
                                                </td>
                                                <td className='text-right'>
                                                    {d.booking_link_15_slug ? 
                                                        <A className="btn btn-success btn-sm" href={`${DOMAINS.BOOK}/${d.booking_link_15_slug}`}><i className="fas fa-link mr-2" /> Open</A>
                                                    : '-'}
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>30 Minute Booking</td>
                                                <td>
                                                    {d.booking_link_30_slug ? <A href={`${DOMAINS.BOOK}/${d.booking_link_30_slug}`}>{DOMAINS.BOOK}/{d.booking_link_30_slug}</A> : '-'}
                                                </td>
                                                <td className='text-right'>
                                                    {d.booking_link_30_slug ? 
                                                        <A className="btn btn-success btn-sm" href={`${DOMAINS.BOOK}/${d.booking_link_30_slug}`}><i className="fas fa-link mr-2" /> Open</A>
                                                    : '-'}
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>45 Minute Booking</td>
                                                <td>
                                                    {d.booking_link_45_slug ? <A href={`${DOMAINS.BOOK}/${d.booking_link_45_slug}`}>{DOMAINS.BOOK}/{d.booking_link_45_slug}</A> : '-'}
                                                </td>
                                                <td className='text-right'>
                                                    {d.booking_link_45_slug ? 
                                                        <A className="btn btn-success btn-sm" href={`${DOMAINS.BOOK}/${d.booking_link_45_slug}`}><i className="fas fa-link mr-2" /> Open</A>
                                                    : '-'}
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>60 Minute Booking</td>
                                                <td>
                                                    {d.booking_link_60_slug ? <A href={`${DOMAINS.BOOK}/${d.booking_link_60_slug}`}>{DOMAINS.BOOK}/{d.booking_link_60_slug}</A> : '-'}
                                                </td>
                                                <td className='text-right'>
                                                    {d.booking_link_60_slug ? 
                                                        <A className="btn btn-success btn-sm" href={`${DOMAINS.BOOK}/${d.booking_link_60_slug}`}><i className="fas fa-link mr-2" /> Open</A>
                                                    : '-'}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    
                                </div>
                            )) : (
                                <CardFooter>
                                    <div>No booking links were found for this team member.</div>
                                </CardFooter>
                                
                            )}
                        </div>
                    ) : (
                        null
                    )}
                  

            </Card>

        </Container>
        </>
       
    )

}

const mapStateToProps = state => {
	return {
        DOMAINS: state.load.DOMAINS,
        selected_division: state.state.selected_division,
	    viewing_user: state.auth.viewing_user,
	};
};

export default connect(mapStateToProps, '')(memo(MattersViewMainLogs));


