import { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import api from 'api';
import moment from 'moment';

const MyTasks = ({matter, banner, onSetTab}) => {

    const [now] = useState(Math.floor(new Date() / 1000))
    const [outstandingItems, setOutstandingItems] = useState(null)

    const findTasks = useCallback(async () => {
        setOutstandingItems(null);
        const response = await api.outstanding_items.findByMatter(matter._id, false)
        if(response.data) {
            const clientActionNeeded =  response.data.filter(f => f.client_action_needed)
            const clientActionNotNeeded =  response.data.filter(f => !f.client_action_needed)

            setOutstandingItems(clientActionNeeded.concat(clientActionNotNeeded))
        }
    }, [matter._id])

    useEffect(() => {
        findTasks()
    }, [findTasks])

    if(!outstandingItems || !outstandingItems.length) return '';

    return outstandingItems.map(i => {

        let color = 'info';

        if(i.due_date) {
            color = 'warning';
            if(i.client_action_needed) color = 'info'
            if(i.due_date < now) color = 'danger'
        }

        return (
            <p key={i._id} className={`cursor-pointer text-sm my-1 bg-${color} ${i.client_action_needed ? "text-uppercase font-weight-bold" : ""} text-white rounded px-4 border mr-2 my-1`} style={banner} onClick={() => onSetTab('Items')}>
                {i.client_action_needed ? <span> <i className="fas fa-users mr- text-white" />  <i className="fas fa-exclamation-triangle mr-2 text-white" /></span> : ''} {i.name} - Due By: {i.due_date ? moment.unix(i.due_date).format('MM/DD') : ''} 
            </p>
        )
    })
  
}


const mapStateToProps = state => {
	return {
        viewing_user: state.auth.viewing_user,
        matter: state.matters.selected_matter,
	};
};

export default connect(mapStateToProps, '')(MyTasks);