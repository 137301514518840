import ReactSelect from 'components/functional/inputs/ReactSelect';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, CardHeader, CardTitle, CardFooter, Row, Col } from "reactstrap";

import A from 'components/markup/links/A';
import Circle from 'components/markup/loading/Circle';
import Dots from 'components/markup/loading/Dots';

import api from 'api';
import moment from 'moment';

import { toast } from 'react-toastify';

import { formatCurrency } from 'utils/currency';
import { onDownloadDocument } from 'utils/documents';

const BankruptcyCreditReports = ({ matter }) => {

    const [reports, setReports] = useState(null)
    const [mainContacts, setMainContact] = useState(null)
    const [contact, setContact] = useState({})
    const [contactLoading, setContactLoading] = useState(true)
    const [social, setSocial] = useState(true)
    const [reportType, setReportType] = useState('efx1bReportScore')
    const [errs, setErrs] = useState([])
    
    const [loading, setLoading] = useState(false)
    const [report, setReport] = useState(null)

    const onPullReport = useCallback(async () => {
        if(!contact) return;

        setErrs([])

        if(!social || ( social && social.length < 4 )) return setErrs([`A social security number must be provided with at least 4 digits.`])
        if(!contact.given_name_unformatted) return setErrs([`A contact's first name must be set.`])
        if(!contact.family_name_unformatted) return setErrs([`A contact's last name must be set.`])
        if(!contact.dob) return setErrs([`A contact's date of birth must be set.`])
        if(!contact.address_line_1) return setErrs([`A contact's street address must be set.`])
        if(!contact.city) return setErrs([`A contact's city must be set.`])
        if(!contact.state) return setErrs([`A contact's state must be set.`])
        if(!contact.postal_code) return setErrs([`A contact's zip code must be set.`])

        setLoading(true)
        const result = await api.modules.bk.credit_reports.download({
            contact: contact._id,
            matter: matter._id,
            report_type: reportType
        })
        setLoading(false)

        if(!result.success) return setErrs(result.message)
        result.data.creditors.sort((a, b) => a.balance > b.balance ? -1 : 1)

        setReport(result.data)

    }, [contact, matter._id, reportType, social])
  
    const onPageRefresh = useCallback(async () => {
        if(!contact) return;
        setContactLoading(true)
        const c = await api.contacts.findById(contact._id);
        if(!c.data) return toast.info(`Could not fetch contact, please refresh your page.`)

        const s = await api.social_security_numbers.findByContact(contact._id)
        if(!s.success) return toast.info(`Could not fetch social security number. Please refresh your page or contact your system admin.`)

        setSocial(s.data)
        setContact(c.data)
        setContactLoading(false)

    }, [contact])
   
    const onSetContact = useCallback(async (contact_id) => {
        setContactLoading(true)
        const c = await api.contacts.findById(contact_id);
        if(!c.data) return toast.info(`Could not fetch contact, please refresh your page.`)

        const s = await api.social_security_numbers.findByContact(contact_id)
        if(!s.success) return toast.info(`Could not fetch social security number. Please refresh your page or contact your system admin.`)

        setSocial(s.data)
        setContact(c.data)
        setContactLoading(false)

    }, [])

    const fetchReports = useCallback(async () => {
        const result = await api.modules.bk.credit_reports.findByMatter(matter._id)
        setReports(result.data ? result.data : [])
    }, [matter._id])

    useEffect(() => {
        const main = matter.parties.filter(p => p.contact && p.workflow_contact && p.workflow_contact.main_contact)
        const contacts = main.map(c => c.contact)
        setMainContact(main.map(c => c.contact));
        onSetContact(contacts[0] ? contacts[0]._id : {})
    }, [matter.parties, onSetContact])

    useEffect(() => {
        window.addEventListener('focus', onPageRefresh)

        return () => {
            window.removeEventListener('focus', onPageRefresh)
        }
    }, [onPageRefresh])

    useEffect(() => {
        fetchReports()
    }, [fetchReports])

    return (

        <div>

            <h2 className='text-warning mb-4'>Credit Reports</h2>

            {!mainContacts ? (
                <Circle />
            ) : !mainContacts.length ? (
                <div></div>
            ) : (
                <div>

                    {!reports ? <Circle /> : !reports.length ? '' : reports.map(r => (
                        <Card key={r._id}>
                            <CardHeader>
                                <Row>
                                    <Col md={6} className='align-self-center'>
                                        <CardTitle className="mb-0">{r.contact ? r.contact.display_name : ''}</CardTitle>
                                    </Col>
                                    
                                    <Col md={6} className='align-self-center text-right' onClick={() => onDownloadDocument(r.document)}>
                                        <button className='btn btn-info btn-sm' ><i className="fas fa-download mr-2" /> Download</button>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col md={6}>
                                        <p className='text-sm mb-0'><b className='d-inline-block text-muted' style={{width: 200}}>Credit Score: </b> {r.credit_score}</p>
                                        <p className='text-sm mb-0'><b className='d-inline-block text-muted' style={{width: 200}}>Payoff Months Without Interest: </b> {r.months_to_pay_off_straight}</p>
                                        <p className='text-sm mb-0'><b className='d-inline-block text-muted' style={{width: 200}}>Type : </b> {r.type === 'efx1bReportScore' ? '1B' : '3B'}</p>
                                      
                                    </Col>
                                    <Col md={6}>
                                        <p className='text-sm mb-0'><b className='d-inline-block text-muted' style={{width: 200}}>Total Debt: </b> {formatCurrency(r.total_debt)}</p>
                                        <p className='text-sm mb-0'><b className='d-inline-block text-muted' style={{width: 200}}>Monthly Payment: </b> {formatCurrency(r.total_monthly_payment)}</p>
                                        <p className='text-sm mb-0'><b className='d-inline-block text-muted' style={{width: 200}}>Date Pulled: </b> {moment.unix(r.created_at).format('MM/DD/YYYY')}</p>
                                    </Col>
                                </Row>
                            </CardBody>

                            <CardFooter>
                                <h2><i className="fas fa-exclamation-triangle text-danger mr-2" /> Credit Factors</h2>
                                {r.factors && r.factors.length ? (
                                    <ol className='pl-4 mb-0'>
                                        {r.factors.map((f, i) => (
                                            <li key={i} className='text-sm mb-0'>{f}</li>
                                        ))}
                                    </ol>
                                ) : (
                                    <p className='text-sm mb-0'>No factors found affecting credit score.</p>
                                )}
                            </CardFooter>
                        </Card>
                    ))}

                    <ReactSelect
                        formGroup={true}
                        title="Select Contact"
                        value={contact._id}
                        options={mainContacts.map(c => {
                            return { 
                                value: c._id, 
                                label: c.display_name ? c.display_name : 
                                    c.phone ? c.phone : 
                                    c.email ? c.email : 
                                    c.family_name 
                            }
                        })}
                        onChange={(e) => onSetContact(e.value)}
                    />

                    <ReactSelect
                        formGroup={true}
                        title="Report Type"
                        value={reportType}
                        options={[
                            { value: 'efx1bReportScore', label: '1 Bureau (Pre Filing)' },
                            { value: 'credmo3bReportScore', label: '3 Bureaus (Post Filing)' },
                        ]}
                        onChange={(e) => setReportType(e.value)}
                    />

                    {!contact ? null : contactLoading ? <Circle /> : (
                        <div className='mt-4'>
                            <Card>
                                <div className='table-responsive'>
                                    <table className='table'>
                                        <thead>
                                            <tr>
                                                <th>Field</th>
                                                <th>Value</th>
                                            </tr>
                                        </thead>


                                        <tbody>
                                            <tr>
                                                <td>Name</td>
                                                <td>{contact.given_name_unformatted + ' ' + contact.family_name_unformatted}</td>
                                            </tr>
                                            <tr>
                                                <td>Social</td>
                                                <td>{social}</td>
                                            </tr>
                                            <tr>
                                                <td>Date Of Birth</td>
                                                <td>{contact.dob ? moment(contact.dob).format('MM/DD/YYYY') : <i className="fas fa-exclamation-triangle text-danger" />}</td>
                                            </tr>
                                            <tr>
                                                <td>Street</td>
                                                <td>{contact.address_line_1 ? contact.address_line_1 : <i className="fas fa-exclamation-triangle text-danger" />}</td>
                                            </tr>
                                            <tr>
                                                <td>City</td>
                                                <td>{contact.city ? contact.city : <i className="fas fa-exclamation-triangle text-danger" />}</td>
                                            </tr>
                                            <tr>
                                                <td>State</td>
                                                <td>{contact.state ? contact.state : <i className="fas fa-exclamation-triangle text-danger" />}</td>
                                            </tr>
                                            <tr>
                                                <td>Zip Code</td>
                                                <td>{contact.postal_code ? contact.postal_code : <i className="fas fa-exclamation-triangle text-danger" />}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </Card>

                            {report ? (
                                <div>
                                    <Card>
                                        <div className='table-responsive'>
                                            <table className='table'>

                                                <thead>
                                                    <tr>
                                                        <th>Report</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    <tr>
                                                        <td>Credit Score</td>
                                                        <td>{report.report.credit_score}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Factors</td>
                                                        <td>{report.report.factors.map((f, i) => (
                                                            <div kye={i}>{f}</div>
                                                        ))}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Total Debt</td>
                                                        <td>{formatCurrency(report.report.total_debt)}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Total Monthly Payment</td>
                                                        <td>{formatCurrency(report.report.total_monthly_payment)}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </Card>
                                    <Card>
                                        <div className='table-responsive'>
                                            <table className='table'>

                                                <thead>
                                                    <tr>
                                                        <th>Creditor</th>
                                                        <th>Debt Type</th>
                                                        <th>Balance</th>
                                                        <th>Monthly Payment</th>
                                                        <th>Last Payment Date</th>
                                                    </tr>
                                                </thead>
                                         
                                                <tbody>
                                                   {report.creditors.map((c, i) => (
                                                    <React.Fragment key={i}>
                                                        <tr key={i}>
                                                            <td>{c.name}</td>
                                                            <td>{c.type}</td>
                                                            <td>{formatCurrency(c.balance)}</td>
                                                            <td>{formatCurrency(c.monthly_payment)}</td>
                                                            <td>{moment(c.last_payment_date).format('MM/DD/YYYY h:mm A')}</td>
                                                        </tr>
                                                    </React.Fragment>
                                                   ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </Card>
                                </div>
                            ) : ''}

                            {errs.length ? (
                                <Card>
                                    <CardBody>
                                        {errs.map((e, i) => (
                                            <p key={i} className='text-sm mb-0 text-danger'>{e}</p>
                                        ))}
                                    </CardBody>
                                </Card>
                            ) : ''}

                            <div className='text-right'>
                                <A href={`/contacts/${contact.id}`} className='btn btn-outline-info'>Update Contact</A>
                                {loading ? (
                                    <button disabled={true} onClick={onPullReport} className='btn btn-warning'>Pulling Report<Dots /></button>
                                ) : (
                                    <button onClick={onPullReport} className='btn btn-success'>Pull Report</button>
                                )}

                            </div>

                        </div>
                    )}

                </div>
            )}

        </div>
    )
}


const mapStateToProps = state => {
	return {
        matter: state.matters.selected_matter,
	};
};

export default connect(mapStateToProps, '')(BankruptcyCreditReports);
