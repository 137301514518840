import { useCallback, useEffect, useState } from 'react';

import { Card, CardBody, CardFooter, Form } from "reactstrap";
import { toggleStandardLoader } from 'store/functions/system/system';
import { setViewingUser } from 'store/functions/auth/auth';

import api from 'api';

import { toast } from 'react-toastify';

import ReactSelect from 'components/functional/inputs/ReactSelect';
import Circle from 'components/markup/loading/Circle';

import ApiError from 'components/markup/layout/ApiError'

const UserSettingsMatter = () => {

    const [err, setErr] = useState(null)
    const [user, setUser] = useState(null)

    const onSave = useCallback(async (e) => {
        e.preventDefault();

        if(!user.email) return toast.info('An email address must be set.')
        if(!user.phone) return toast.info('An phone number must be set.')

        toggleStandardLoader(true)
        const updated = await api.users.update(user._id, user)
        toggleStandardLoader(false)

        if(!updated.success) return toast.error('Something went wrong updating your profile, please try again')
        setViewingUser()
    }, [user])

    const onSettingsChange = useCallback((field, value) => {
        const newUser = JSON.parse(JSON.stringify(user));
        newUser.settings[field] = value;
        setUser(newUser)
    }, [user])

    const fetchData = useCallback(async () => {
        const viewing_user = await api.users.me()
        if(!viewing_user.data) return setErr(viewing_user.message)
        setUser(viewing_user.data)
    }, [])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    if(err) return <ApiError err={err} />
    if(!user) return <Circle className="pt-4" />

    return (
        <Form>
        <Card>
            <CardBody>

                <h6 className="heading-small text-muted mb-4">Displayed Data</h6>

                <ReactSelect
                    title={"Default Number Of Rows Per Table"}
                    formGroup={true}
                    placeholder=""
                    onChange={(obj) => onSettingsChange('default_table_size', obj.value)}
                    options={[
                        { value: 5,   label: 5   },
                        { value: 10,  label: 10  },
                        { value: 15,  label: 15  },
                        { value: 20,  label: 20  },
                        { value: 25,  label: 25  },
                        { value: 50,  label: 50  },
                    ]}
                    value={user.settings.default_table_size}
                />    
            </CardBody>

            <CardFooter className="text-right">
                <button className="btn btn-success btn-sm" onClick={onSave} >
                    Save Profile
                </button>
            </CardFooter>

        </Card>

    </Form>

    );
}

export default UserSettingsMatter