import { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, CardFooter, CardHeader, CardTitle } from "reactstrap";
import { setViewingUser } from 'store/functions/auth/auth';

import api from 'api';

import { toast } from 'react-toastify';

import ObjectFinder from 'components/system/Objects/Finder';

import DayAndTimeRange from 'components/settings/DayAndTimeRange';
import TimeZone from 'components/settings/TimeZone';
           
const Availability = ({ d, viewing_user }) => {

    const [settings, setSettings] = useState(d.availability ? {
        timezone: d.timezone ? d.timezone : 'America/New_York',
        availability: d.availability,
    } : {
        timezone: d.timezone ? d.timezone : 'America/New_York',
        availability: {
            mon: { start: 32400, end: 61200 },
            tue: { start: 32400, end: 61200 },
            wed: { start: 32400, end: 61200 },
            thu: { start: 32400, end: 61200 },
            fri: { start: 32400, end: 61200 },
            sat: { start: 0, end: 0 },
            sun: { start: 0, end: 0 },
        }
    })

    const onInputChange = useCallback((field, value) => {
        const state = JSON.parse(JSON.stringify(settings))
        state[field] = value;
        setSettings(state)
    }, [settings])

    const onSave = useCallback(async () => {

        const updated = await api.users.divisionOverride(viewing_user._id, {
            division: d.division,
            timezone: settings.timezone,
            availability: settings.availability
        })

        if(!updated.success) return toast.error(`Could not save availability at this time, please try again`)
        
        toast.success(`Availability updated successfully.`)
        setViewingUser()
    }, [d.division, settings.availability, settings.timezone, viewing_user._id])

   
    return (
        <Card>
            <CardHeader>
                <CardTitle className='mb-0'><ObjectFinder collection="divisions" _id={d.division} /></CardTitle>
            </CardHeader>

            <CardBody>
                
                <TimeZone 
                    timezone={settings.timezone}
                    setTimezone={(value) => onInputChange('timezone', value)}
                />    

                <DayAndTimeRange 
                    times={settings.availability}
                    setTimes={(value) => onInputChange('availability', value)}
                />       
            </CardBody>

            <CardFooter className='text-right'>
                <button onClick={onSave} className='btn btn-success'>Save Availability</button>
            </CardFooter>
        </Card>
    )
}

const UserSettingsMatter = ({viewing_user}) => {

    if(!viewing_user.division_overrides.length) return (
        <Card>
            <CardHeader>
                <CardTitle className="mb-0">Availability</CardTitle>
            </CardHeader>

            <CardBody>
                <p className='text-sm mb-0'>Your account is not setup to accept availability. Contact your system admin.</p>
            </CardBody>
        </Card>
    )

    return viewing_user.division_overrides.map(d => 
        <Availability key={d._id} d={d} viewing_user={viewing_user} />
    )

}


const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user,
    };
};

export default connect(mapStateToProps, '')(UserSettingsMatter);