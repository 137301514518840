import { useState } from 'react';

import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';

import MFA from '..//MFA';
import AccountInfo from './AccountInfo';
import Data from './Data';
import Password from './Password';
import ErrorLogs from './ErrorLogs';
import EmailSync from './EmailSync';
import Audio from './Audio';
import Availability from './Availability';
import BookingLinks from './BookingLinks';

import HeaderNavigation from 'components/markup/layout/HeaderNavigation';
import InternalSidebar from 'components/markup/layout/InternalSidebar';

const AccountSettings = () => {

    const [tab, setTab] = useState(0)

    return (

        <div>
             <Helmet>
                <title>Settings</title>
                <meta name="description" content="Settings" />
            </Helmet>

            <HeaderNavigation 
                title="My Account"
            />

            <InternalSidebar 
                tab={tab}
                onClick={(route, tab) => setTab(tab)}
                routes={[
                    { name: 'Account Info' },
                    { name: 'Login & Password' },
                    { name: 'Multi-Factor Auth' },
                    { name: 'Data' },
                    { name: 'Error Logs' },
                    { name: 'Email Sync' },
                    { name: 'Availability' },
                    { name: 'Booking Links' },
                    { name: 'Audio' },
                ]}
            >
                {tab === 0 ? (
                     <AccountInfo />
                ) : tab === 1 ? (
                    <Password />
                ) : tab === 2 ? (
                    <MFA />
                ) : tab === 3 ? (
                    <Data />
                ) : tab === 4 ? (
                    <ErrorLogs />
                ) : tab === 5 ? (
                    <EmailSync />
                ) : tab === 6 ? (
                    <Availability />
                ) : tab === 7 ? (
                    <BookingLinks />
                ) : tab === 8 ? (
                    <Audio />
                ) : null}
            </InternalSidebar>
            
        </div>
    )

}

const mapStateToProps = state => {
    return {
        config: state.config,
        company: state.company.company,
        viewing_user: state.auth.viewing_user,
    };
};

export default connect(mapStateToProps, '')(AccountSettings);