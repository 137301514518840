import { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Card, CardFooter, CardHeader, CardTitle, Col, Row, Badge } from "reactstrap";

import ModalToggler from 'components/functional/modals/Toggler';
import Circle from 'components/markup/loading/Circle';

import api from 'api';
import moment from 'moment';

import Add from './Add';
import Edit from './Edit';

import { toast } from 'react-toastify';
import { getUrlParameter } from 'utils/urls';

const CreditorCard = ({title, fetchData, objections, hasOpenedUrlLink, fetchInfo}) => (
    <Card>
        <CardHeader>
            <Row>
                <Col md={6} className='align-self-center'>
                    <CardTitle className='mb-0'>{title}</CardTitle>
                </Col>
                <Col md={6} className='align-self-center text-right'>
                    <ModalToggler component={Add} onSuccess={fetchData} fetchInfo={fetchInfo}>
                        <button className='btn btn-sm btn-success'><i className="fas fa-plus mr-2" /> Add</button>
                    </ModalToggler>
                </Col>
            </Row>
        </CardHeader>

        <div className="table-responsive">
            <table className="table">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Status</th>
                        <th>Severity</th>
                        <th>Documents</th>
                        <th>Date Filed</th>
                        <th className='text-right'>#</th>
                        <th className='text-right'></th>
                    </tr>
                </thead>

                <tbody>
                    {objections.length ? objections.map((p, i) => (
                        <tr>
                            <td>
                                <span className='cursor-pointer'>
                                    <ModalToggler component={Edit} onSuccess={fetchData} fetchInfo={fetchInfo} objection_id={p._id}>
                                        {p.name}
                                    </ModalToggler>
                                </span>
                            </td>
                            {console.log(p)}
                            <td className='text-capitalize'>
                                {p.status === 'open' ? (
                                    <Badge style={{width: 94 }} color="warning">Open</Badge>
                                ) : (
                                    <Badge style={{width: 94 }} color="success">Resolved</Badge>
                                )}
                            </td>
                            <td>{p.severity === 1 ? 'Normal' : p.severity === 2 ? 'Important' : 'Severe'}</td>
                            <td>{p.documents.length}</td>
                            <td style={{whiteSpace: 'pre-line'}}>{p.date_filed ? moment(p.date_filed).format('MM/DD/YYYY') : ''}</td>
                            <td style={{whiteSpace: 'pre-line'}} className='text-right'>
                                {/* {moment.unix(p.created_at).format('MM/DD/YYYY')} */}
                                {p.objection_number}
                            </td>
                            <td className='text-right'>
                                <ModalToggler 
                                    component={Edit} 
                                    onSuccess={fetchData} 
                                    objection_id={p._id} 
                                    fetchInfo={fetchInfo}
                                    showModal={getUrlParameter('objection') === p._id && !hasOpenedUrlLink}
                                >
                                    <button className='btn btn-sm btn-outline-info'><i className="fas fa-edit mr-2" /> Edit</button>
                                </ModalToggler>
                            </td>
                        </tr>
                    )) : (
                        <tr>
                            <td>No objections have been added yet.</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>

        <CardFooter>
            <ModalToggler component={Add} onSuccess={fetchData} fetchInfo={fetchInfo}>
                <button className='btn btn-sm btn-success'><i className="fas fa-plus mr-2" /> Add</button>
            </ModalToggler>
        </CardFooter>
    </Card>
)

const Bankruptcyobjections = ({matter, hasOpenedUrlLink, setHasOpenedUrlLink, fetchInfo}) => {

    const [objections, setObjections] = useState(null);

    const fetchData = useCallback(async () => {
        const result = await api.modules.bk.objections.findByMatter(matter._id)
        if(!result.success) return toast.info(`Something went wrong fetching objections for this case, please reload your page.`)

        setObjections(result.data)
        setTimeout(() => {
            setHasOpenedUrlLink(true)
        }, 500)

    }, [matter._id, setHasOpenedUrlLink])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    if(!objections) return <Circle />

    return (

        <div>

            <h2 className='text-warning mb-4'>Objections</h2>

            <CreditorCard 
                title="Trustee Objections"
                fetchData={fetchData}
                objections={objections.trustee}
                hasOpenedUrlLink={hasOpenedUrlLink}
                fetchInfo={fetchInfo}
            />

            {objections.creditor && objections.creditor.length ? objections.creditor.map(doc => (
                <CreditorCard 
                    title={`Creditor: ${doc.creditor ? doc.creditor.name : 'Not Found'}`}
                    fetchData={fetchData}
                    objections={doc.docs}
                    hasOpenedUrlLink={hasOpenedUrlLink}
                    fetchInfo={fetchInfo}
                />
            )) : (
                <h2 className='text-center mt-5'><i className="fas fa-check-circle mr-2 text-success" /> No Creditor Objections Found</h2>
            )}
        </div>
    )
}


const mapStateToProps = state => {
    return {
        matter: state.matters.selected_matter,
    };
};

export default connect(mapStateToProps, '')(Bankruptcyobjections);