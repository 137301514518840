import { FormGroup, Input } from "reactstrap";
import DatePicker from 'react-datepicker'
import moment from 'moment';

const ContactsUpdate = ({onInputChange, contact}) => {
    return (
        <div>

            <div className="bg-secondary border p-3 mb-3">
                <p className="text-sm mb-0">
                    {contact.password ? 'To change this contacts portal password enter a new one below.' : 'This contact does not have a password set up, you may enter one below.'}
                </p>
            </div>

            <FormGroup>
                <label className="form-control-label">{contact.password ? 'Enter New Password' : 'Set Password Below'}</label>
                <Input 
                    type="text"
                    value={contact.new_password}
                    onChange={e => onInputChange('new_password', e.target.value)}
                />
            </FormGroup>

        </div>
    )
}

export default ContactsUpdate;